import React from "react";
import { Row, Col } from "reactstrap";
import Session from "./Session";
import styled from "styled-components";

const SessionBrowser = ({ sessionsPages, currentPage, setCurrentPage, totalCount }) => {
  let firstNumber = currentPage * 10 + 1;
  let lastNumber = firstNumber + sessionsPages[currentPage]?.length - 1;


  return (
    <Row>
      {totalCount > 0 && (
        <>
          {/* ########## Pagination Starts ####################*/}
          <SessionCount lg={4} sm={12}>
            Displaying {firstNumber}-{lastNumber} of {totalCount}
          </SessionCount>
          <Pagination lg={8} sm={12}>
            <PaginationButton
              onClick={() => {
                  setCurrentPage(currentPage - 1)
              }}
              disabled={!currentPage > 0}
            >
              Prev Page
            </PaginationButton>
            {sessionsPages.map((page, index) => (
              <PaginationButton key={index} onClick={() => { setCurrentPage(index) }} active={currentPage === index}>{index + 1}</PaginationButton>
            ))}
            <PaginationButton onClick={() => { setCurrentPage(currentPage + 1)}} disabled={sessionsPages.length == (currentPage + 1)}>Next Page</PaginationButton>
          </Pagination>
          {/* ########## End Pagination ####################*/}
          {/* ########## Sessions  ####################*/}
          {sessionsPages[currentPage]?.map(session => {
            return (
              <StyledCol lg={12} key={session.Session_ID}>
                <Session data={session} />
              </StyledCol>
            );
          })}
          {/* ########## End Sessions ####################*/}
          {/* ########## Pagination Starts ####################*/}
          <SessionCount lg={4} sm={12}>
          <span></span>
            Displaying {firstNumber}-{lastNumber} of {totalCount}
          </SessionCount>
          <Pagination lg={8} sm={12}>
            <span></span>
            <PaginationButton
              onClick={() => {
                  setCurrentPage(currentPage - 1)
              }}
              disabled={!currentPage > 0}
            >
              Prev Page
            </PaginationButton>
            {sessionsPages.map((page, index) => (
              <PaginationButton key={index} onClick={() => { setCurrentPage(index) }} active={currentPage === index}>{index + 1}</PaginationButton>
            ))}
            <PaginationButton onClick={() => { setCurrentPage(currentPage + 1)}} disabled={sessionsPages.length == (currentPage + 1)}>Next Page</PaginationButton>
          </Pagination>
          {/* ########## End Pagination ####################*/}
        </>
      )}

      {totalCount == 0 && (
        <Alert role="alert">
          <p>No sessions matching your criteria are available.</p>
        </Alert>
      )}
    </Row>
  );
};

export default SessionBrowser;

const StyledCol = styled(Col)`
  margin: 15px 0;
`;

const SessionCount = styled(Col)`
  color: #333;
  font-size: 12px;
  text-align: center;

  @media only screen and (min-width: 992px) {
    text-align: left;
  }
`;

const Pagination = styled(Col)` 
text-align: center;

  @media only screen and (min-width: 992px) {
    text-align: right;
  }
`
const PaginationButton = styled.button`
  margin: 0 0.2rem;
  color: ${props => props.active ? "#000" : "#333"};
  opacity: ${props => props.active ? "1" : "0.75"};
  font-size: 12px;
  border: 0;
  background: transparent;
  text-decoration: ${props => props.active ? "underline" : ""};
  font-weight: ${props => props.active ? "bold" : ""};

  &:hover, &:focus{
    opacity: 1;
    color: #0085ad;
  }
  &:last-of-type {
    margin-right: 0;
  }
`

const Alert = styled.div`
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  border: 1px solid #ecf0f1;
  border-radius: 2px;

  p {
    margin: 0;
  }
`;
