import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import reason00 from "../../images/5reasons/jpg/0.jpg";
import reason01 from "../../images/5reasons/jpg/1.jpg";
import reason02 from "../../images/5reasons/jpg/2.jpg";
import reason03 from "../../images/5reasons/jpg/3.jpg";
import reason04 from "../../images/5reasons/jpg/4.jpg";
import reason05 from "../../images/5reasons/jpg/5.jpg";
import reason06 from "../../images/5reasons/jpg/6.jpg";
import carets from "../../images/5reasons/svg/5-carets.svg";
import top1 from "../../images/5reasons/svg/top1.svg";
import top2 from "../../images/5reasons/svg/top2.svg";
import top3 from "../../images/5reasons/svg/top3.svg";
import top4 from "../../images/5reasons/svg/top4.svg";
import top5 from "../../images/5reasons/svg/top5.svg";
import "./style.scss";

const Reasons = () => {
  const [mobileActiveIndex, setMobileActiveIndex] = useState(1);
  const isTablet = useMediaQuery({
    query: `(min-width: 768px)`,
  });

  return (
    <main id="5reasons">
      <div class="page__Content-x row">
        <div class="col">
          <div class="Top5Inforgraph">
            <div>
              <h3>
                <strong>TOP 5</strong>
                <br />
                REASONS
                <br />
                TO ATTEND
              </h3>
              <h5 class="infologo">
                San Diego, California <span> | </span> November 2-5
              </h5>
            </div>
            <div>
              <img src={reason01} />
              <h3>CONNECT</h3>
              <p>
                Strengthen your professional ties by getting to know peers,
                utility professionals and industry leaders from across the
                globe. Attend the Welcome Reception and Opening Ceremony and
                give back to the communities we support through one of the
                volunteer opportunities.
              </p>
            </div>
            <div>
              <img src={reason02} />
              <h3>LEARN</h3>
              <p>
                Power up your skills with our Hands-On Learning Labs that give
                you an up-close view of product demonstrations. Experience
                panels and presentations on topics that matter most to you and
                the utility you serve each day.
              </p>
            </div>
            <div>
              <img src={reason03} />
              <h3>GROW</h3>
              <p>
                Gain insights on the most recent industry news and trends. Our
                speakers are industry leaders with years of experience who are
                passionate about sharing what they’ve learned, what’s on the
                horizon and how they can help you.
              </p>
            </div>
            <div>
              <img src={reason04} />
              <h3>DISCOVER</h3>
              <p>
                Explore San Diego! Visit the Georgia Aquarium – the largest
                aquarium in the U.S. and a Xylem partner. Take an interactive
                peek into the history of an iconic beverage at The World of
                Coca-Cola. Get a cultural fix at the many museums, parks and
                neighborhoods such as the High Museum of Art, the National
                Center for Civil & Human Rights and Grant Park. Dine in a foodie
                paradise with every cuisine imaginable.
              </p>
            </div>
            <div>
              <img src={reason05} />
              <h3>SHARE</h3>
              <p>
                Bring home everything you’ve learned and share it with your
                entire organization. Apply the knowledge you’ve learned from
                Xylem Reach and your peers.
              </p>
            </div>
            <div class="cta-block">
              <p class="mb-0">
                Questions? Contact us at
                <br />
                <a href="mailto://reach.team@xylem.com">
                  <strong>reach.team@xylem.com</strong>
                </a>
              </p>
              <span>
                <a
                  class="primary-cta mt-3"
                  href="https://cvent.me/b4EWxL?RefId=reach25-registration"
                  target="_blank"
                >
                  Register
                  <br />
                  Today!
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Reasons;
