import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import "./style.scss";

const TableRow = props => {
  const isTablet = useMediaQuery({
    query: `(min-width: 768px)`
  });
  let children = React.Children.toArray(props.children);
  if (!isTablet) {
    let filteredRows = [];
    filteredRows[0] = children[0];
    filteredRows[1] = children[props.activeMobileRow];
    return <tr>{filteredRows}</tr>;
  } else {
    return <tr>{children}</tr>;
  }
};

const PricingTable = () => {
  const [mobileActiveIndex, setMobileActiveIndex] = useState(1);
  const isTablet = useMediaQuery({
    query: `(min-width: 768px)`
  });

  return (
    <div className="pricing-table">
      <ul className="pricing-mobile">
        {/* <li className={mobileActiveIndex === 1 ? "bg-green active" : "bg-blue"}>
          <button className="th" onClick={() => setMobileActiveIndex(1)}>
            Early-Bird Registration
          </button>
        </li> */}
        <li className={mobileActiveIndex === 1 ? "bg-active active" : "bg-blue"}>
          <button className="th" onClick={() => setMobileActiveIndex(1)}>
            Standard
          </button>
        
         </li>
         {/* -- reset mobileActiveIndex for all on uncomment --
        <li className={mobileActiveIndex === 2 ? "bg-active active" : "bg-blue"}>
          <button className="th" onClick={() => setMobileActiveIndex(2)}>
            Guest
          </button>
        </li>
        <li className={mobileActiveIndex === 3 ? "bg-active active" : "bg-blue"}>
          <button className="th" onClick={() => setMobileActiveIndex(3)}>
            Exhibitor
          </button>
        </li> */}
        <li className={mobileActiveIndex === 2 ? "bg-active active" : "bg-blue"}>
          <button className="th" onClick={() => setMobileActiveIndex(2)}>
            Virtual
          </button>
        </li>
      </ul>
      <table>
        <thead>
          <tr>
            <th className="hide"></th>
            {/* <th className="bg-active">
              Early Bird
              <span className="d-block small">Ends July 1, 2021</span>
            </th> */}
            <th className="bg-blue">Standard</th>
            {/* <th className="bg-blue default">Guest</th>
            <th className="bg-blue default">Exhibitor*</th> */}
            <th className="bg-blue default">Virtual</th>
          </tr>
        </thead>
        <tbody>
          <TableRow activeMobileRow={mobileActiveIndex}>
            <td>Full Conference Pass</td>
            {/* <td>
              <span className="txt-top">$</span>
              <span className="txt-l">800</span>
            </td> */}
            <td>
              <span className="txt-top">$</span>
              <span className="txt-l">750</span>
            </td>
            {/* <td>
              <span className="txt-l">-</span>
            </td>
            <td>
              <span className="txt-top">$</span>
              <span className="txt-l">350</span>
            </td> */}
            <td>
              <span className="txt-top">$</span>
              <span className="txt-l">49</span>
            </td>
            
          </TableRow>
          {/* 
          <tr>
            <td className="sep" colSpan={isTablet ? "5" : "2"}></td>
          </tr>
          <TableRow activeMobileRow={mobileActiveIndex}>
            <td>Welcome Reception</td>
            <td>
              <span className="tick">✔</span>
            </td>
            <td>
              <span className="txt-top">$</span>
              <span className="txt-l">50</span>
            </td>
            <td>
              <span className="tick">✔</span>
            </td>
            <td>
              <span className="txt-l">-</span>
            </td>
          </TableRow>
          <TableRow activeMobileRow={mobileActiveIndex}>
            <td>Closing Happy Hour</td>
            {/* <td>
              <span className="tick">✔</span>
            </td> */}
            {/* <td>
              <span className="tick">✔</span>
            </td>
            <td>
              <span className="txt-l">-</span>
            </td>
            <td>
              <span className="tick">✔</span>
            </td>
            <td>
              <span className="tick">✔</span>
            </td>
          </TableRow>
          <TableRow activeMobileRow={mobileActiveIndex}>
            <td>Watermark Event</td> */}
            {/* <td>
              <span className="tick">✔</span>
            </td> */}
            {/*
            <td>
              <span className="tick">✔</span>
            </td>
            <td>
              <span className="tick">✔</span>
            </td>
            <td>
              <span className="tick">✔</span>
            </td>
            <td>
              <span className="tick">✔</span>
            </td>
          </TableRow> */}
        </tbody>
      </table>
      {/* <p className="pt-3 note">
        <span className="tick">✔</span> - Included with Registration
        <br />* - Sponsorship and Exhibitor Packages are additional costs.
      </p> */}
    </div>
  );
};

export default PricingTable;
