import React, { useEffect, useState } from "react";
import { StaticQuery, graphql, useStaticQuery } from "gatsby";
import { Row, Col } from "reactstrap";
import SessionBrowser from "./SessionBrowser";
import SessionFilter from "./SessionFilter";

const Sessions = () => {
  const data = useStaticQuery(graphql`
    query SessionsQuery {
      allSessionsJson(sort: { fields: Time_Stamp }) {
        nodes {
          Title
          Venue
          Geo
          Tags
          All_Tags
          Date
          Start_Time
          End_Time
          Int_Start_Time
          Time_Stamp
          Description
          Speaker
        }
        totalCount
        distinct(field: All_Tags)
      }
    }
  `);

  const paginate = (arr, size) => {
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);

      return acc;
    }, []);
  };

  function arrayContainsAnotherArray(needle, haystack) {
    for (var i = 0; i < needle.length; i++) {
      if (haystack.indexOf(needle[i]) === -1) {
        return false;
      }
    }
    return true;
  }

  let availableFilters = [];
  data.allSessionsJson.distinct.map((option) => {
    if (option.indexOf(",") > -1) {
      let options = option.split(",");

      options.map((innerOption) => {
        if (availableFilters.indexOf(innerOption.trim()) === -1) {
          availableFilters.push(innerOption.trim());
        }
      });
    } else {
      if (availableFilters.indexOf(option.trim()) === -1) {
        availableFilters.push(option.trim());
      }
    }
  });

  let paginatedSessions = paginate(data.allSessionsJson.nodes, 10);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [sessions, setSessions] = useState(paginatedSessions);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(
    data.allSessionsJson.nodes.length
  );

  useEffect(() => {
    let sessionVenue = ["Livestream", "In Person", "Virtual"];
    if (selectedFilters.length) {
      let filteredSessions = data.allSessionsJson.nodes.filter((session) => {
        let sessionTags = session.All_Tags.split(",").map((tag) => {
          return tag.trim();
        });

        // sessionTags contains selectedFilters
        return arrayContainsAnotherArray(
          selectedFilters.sort(),
          sessionTags.sort()
        );
        //return arrayContainsAnotherArray(selectedFilters.sort(), sessionVenue.sort(), sessionTags.sort());
      });

      setTotalCount(filteredSessions.length);
      setSessions(paginate(filteredSessions, 10));
    } else {
      setTotalCount(data.allSessionsJson.nodes.length);
      setSessions(paginatedSessions);
    }
  }, [selectedFilters]);

  return (
    <Row>
      <Col lg={3}>
        <SessionFilter
          filters={availableFilters.sort()}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </Col>
      <Col lg={9}>
        <SessionBrowser
          sessionsPages={sessions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
        />
      </Col>
    </Row>
  );
};

export default Sessions;
