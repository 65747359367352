import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "reactstrap";
import "./style.scss";

const Sponsor = () => {
  const data = useStaticQuery(graphql`
    query SponsorQuery {
      allSponsorJson {
        group(field: level) {
          fieldValue
          nodes {
            name
            logo
            description
            website
          }
        }
      }
    }
  `);

  const sponsorData = data.allSponsorJson;

  return (
    <div>
      {/* <h3 className="text-left">Past Sponsor</h3>
      <hr className="mb-5" /> */}
      {sponsorData.group.map((level) => {
        const correctFieldValue = level.fieldValue.slice(2);
        return (
          <Row className={correctFieldValue}>
            <Col sm={12} className="text-center mb-3">
              <h3>{correctFieldValue}</h3>
              <hr className="mx-auto" />
            </Col>
            {level.nodes.map((sponsor) => (
              <Row className="mb-5">
                <Col
                  sm={12}
                  md={4}
                  lg={3}
                  className="text-center align-self-center"
                >
                  <a
                    href={"https://" + sponsor.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={"../../images/sponsor/" + sponsor.logo}
                      alt={sponsor.name}
                      style={{ maxWidth: "100%" }}
                    />
                  </a>
                </Col>
                <Col sm={12} md={8} lg={9}>
                  <h3>{sponsor.name}</h3>
                  <p>{sponsor.description}</p>
                  <a
                    href={"https://" + sponsor.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h4>{sponsor.website}</h4>
                  </a>
                </Col>
              </Row>
            ))}
          </Row>
        );
      })}
    </div>
  );
};

export default Sponsor;
