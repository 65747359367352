import React, { useState, useEffect } from "react";

function appendScript(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true);

  const script = document.createElement("script");
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
}

function destyleMktoForm(mktoForm, moreStyles) {
    var formEl = mktoForm.getFormElem()[0];
    var arrayFrom = Function.prototype.call.bind(Array.prototype.slice);

    // remove element styles from <form> and children
    var styledEls = arrayFrom(formEl.querySelectorAll("[style]")).concat(
      formEl
    );
    styledEls.forEach(function(el) {
      el.removeAttribute("style");
    });

    // disable remote stylesheets and local <style>s
    var styleSheets = arrayFrom(document.styleSheets);
    styleSheets.forEach(function(ss) {
      if (
        [mktoForms2BaseStyle, mktoForms2ThemeStyle].indexOf(ss.ownerNode) !=
          -1 ||
        formEl.contains(ss.ownerNode)
      ) {
        ss.disabled = true;
      }
    });

    if (!moreStyles) {
      formEl.setAttribute("data-styles-ready", "true");
    }
}

function useMarketo({ baseUrl, munchkinId, formId, callback, setFormSuccess }) {

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, callback);
      window.MktoForms2.whenRendered(function(form) {
        destyleMktoForm(form);
        // Handle post success
        form.onSuccess(function(form){
            setFormSuccess(true);
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
      });
      return;
    }
    appendScript(baseUrl, setScriptLoaded);
  }, [scriptLoaded, baseUrl, munchkinId, formId, callback]);
}

const MarketoForm = ({ baseUrl, munchkinId, formId, callback, formTitle}) => {

    const [formSuccess, setFormSuccess] = useState(false);

    useMarketo({baseUrl, munchkinId, formId, callback, setFormSuccess});

    if (formTitle=="Resiliency Award"){
          return (
        <div className="embedded-marketo-form">
            <h4>{formTitle}</h4>
            <form id={`mktoForm_${formId}`} className={formSuccess ? "d-none" : "d-block"} />
            <div className={formSuccess ? "d-block" : "d-none"}><p>Thanks for Nominating a Resiliency Award.</p></div>
        </div>
    )
    }
    if (formTitle=="Transformation Award"){
          return (
        <div className="embedded-marketo-form">
            <h4>{formTitle}</h4>
            <form id={`mktoForm_${formId}`} className={formSuccess ? "d-none" : "d-block"} />
            <div className={formSuccess ? "d-block" : "d-none"}><p>Thanks for Nominating a Transformation Award.</p></div>
        </div>
    )
    }
    else {
          return (
        <div className="embedded-marketo-form">
            <h4>{formTitle}</h4>
            <form id={`mktoForm_${formId}`} className={formSuccess ? "d-none" : "d-block"} />
            <div className={formSuccess ? "d-block" : "d-none"}><p>Thanks for signing up to stay up-to-date with Reach.</p></div>
        </div>
    )
    }

}

export default MarketoForm