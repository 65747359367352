import React, { useEffect, useState } from "react";
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import { Row, Col } from "reactstrap";
import SessionBrowser from "./SessionBrowser"

const LearningLabs = () => {
  const data = useStaticQuery(graphql`
    query LearningLabsQuery {
      allLearningLabsJson {
        nodes {
          Title
          Description
          Category
          Capacity
          Start_Time
          End_Time
          Speaker
        }
        totalCount
      }
    }
  `
  )

  const paginate = (arr, size) => {
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size)
      let page = acc[idx] || (acc[idx] = [])
      page.push(val)
  
      return acc
    }, [])
  }

  let paginatedSessions = paginate(data.allLearningLabsJson.nodes, 10);

  const [sessions, setSessions] = useState(paginatedSessions);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(data.allLearningLabsJson.nodes.length);

  return (
    <SessionBrowser sessionsPages={sessions} currentPage={currentPage} setCurrentPage={setCurrentPage} totalCount={totalCount} />
  )
}

export default LearningLabs