import React, { useState, useEffect } from "react";

const CoverImg = ({ Src }) => {

  return (
    <div className="cover-img">
      <img className="w-100" src={Src} alt="" />
    </div>
  )
}

export default CoverImg