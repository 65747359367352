import React from "react";

const VideoEmbed = ({ videoId , ratio}) => {
    return (
        <div
        style={{
          padding: ratio+' 0 0 0',
          position: "relative",
          marginBottom: "1.5rem"
        }}
      >
        <iframe
          src={"https://player.vimeo.com/video/" + videoId}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%"
          }}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    )
}

export default VideoEmbed