import React, { useState } from "react";
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import styled from "styled-components";

const StyledCard = styled(Card)`
    border-radius: 0px;
    border-bottom-width: 0;

    &:last-of-type {
        border-bottom-width: 1px;
    }

    .card-header {
        border-bottom: 0;
        &:hover {
            cursor: pointer;
        }
    }
`;

const FAQs = ({ type }) => {
    let faqs = [];
    let title = "Virtual";

    const [activeFaq, setActiveFaq] = useState(0);

    if (type === "virtual") {
        faqs = [
            {
                "Question": "How do I sign up for the virtual experience?",
                "Answer": "We can’t wait to share our virtual experience with you! Registration will open in late June. When registering, please select the Virtual Attendee option."
            },
            {
                "Question": "Will there be a cost to attend the Reach virtual experience?",
                "Answer": "The cost to register for the virtual experience is $99."
            },
            {
                "Question": "What will be included in the virtual experience?",
                "Answer": "Virtual attendees will have access to on-demand and live content, including training sessions, a keynote, roadmaps, demos, networking opportunities and more."
            },
            {
                "Question": "What is the schedule for the virtual experience?",
                "Answer": "We are still working to finalize the agenda. Please check the website for updates."
            },
            {
                "Question": "When will the virtual experience take place?",
                "Answer": "October 26-27 at the same time as the in-person conference."
            }
        ];
    } else {
        title = "";
        faqs = [
            {
                "Question": "What airport should I fly into?",
                "Answer": "You can either fly into Reagan Nation Airport, 2401 Smith Blvd, Arlington, VA 22202 (25 minutes to hotel via taxi or ride share) or Dulles International Airport, 1 Saarinen Cir, Dulles, VA 20166 (35 minutes to hotel via taxi or ride share)."
            },
            {
                "Question": "How do I book my hotel room?",
                "Answer": "To book your hotel room and view rate information, access our conference hotel room block at <a target=\"_blank\" href=\"https://book.passkey.com/go/2022Reach\">this link</a>."
            },
            {
                "Question": "What is the fee to attend the event?",
                "Answer": "What is the fee to attend the event? This year's event fee is $750 for in person attendees for $49 for virtual attendees."
            },
            {
                "Question": "Can I bring a guest?",
                "Answer": "Guests are required to <a target=\"_blank\" href=\"https://inhousephysicians.formstack.com/workflows/reach_conference_vax_record_neg_test_upload?mkt_tok=MzA2LUNKUi0xMDkAAAGG4fpq-hG02NDJ6XoNxjrRZhgGWW0aUmyum651uiv5ciCtOvMmf-CYEhyoSXues1ZdNRETIeeDfAJqWmeFNJGPQ7YYBzRgja5FmKPv0HgxvVYHyA\">upload a vaccine card or negative COVID-19 test</a>. Guests are welcome to attend the Sunday evening welcome reception and Wednesday closing reception for a fee. Please add your guest when you register. Guests are not invited to other conference activities, including meeting spaces and meals throughout the conference."
            },
            {
                "Question": "What is the dress code?",
                "Answer": "The dress code for the conference is business casual."
            },
            {
                "Question": "What is the cancellation policy?",
                "Answer": "There is no cancellation fee for cancellations received prior to Sept. 23, 2022. Beginning Sept. 23, in-person registration cancellations will incur a fee of $100. There is no cancellation fee for virtual registrations."
            },
            {
                "Question": "What safety measures can I expect at the conference?",
                "Answer": "Please see our <a href=\"/attend/health-safety\">health and safety</a> page for more details."
            },
            {
                "Question": "What will be included in the virtual experience?",
                "Answer": "Virtual attendees will have access to select live-streamed sessions, including training sessions, a keynote, roadmaps, demos and more."
            },
            {
                "Question": "What is the schedule for the virtual experience?",
                "Answer": "All sessions for the in-person and virtual experience can be found on the <a href=\"/learn/sessions\">sessions page</a>."
            },
            {
                "Question": "When will the virtual experience take place?",
                "Answer": "Select sessions will be livestreamed to virtual attendees throughout Oct. 10-12."
            }
        ];
    }

    return (
        <>
            <h3 className="text-center mt-5">{title}</h3>
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <StyledCard key={index}>
                        <CardHeader className="avenir-medium" onClick={() => setActiveFaq(index)}>{faq.Question}</CardHeader>
                        <Collapse isOpen={activeFaq === index}>
                            <CardBody dangerouslySetInnerHTML={{ __html: faq.Answer }} />
                        </Collapse>
                    </StyledCard>
                ))}
            </div>
        </>
    )
}

export default FAQs