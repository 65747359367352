import React, { useState } from "react";
import styled from "styled-components";

{/* const VenueFilters = [
  "In Person",
  "Virtual"
]; */}

const SessionFilter = ({ filters, selectedFilters, setSelectedFilters }) => {
  filters = [
    "Electric",
    "Water",
    "Gas",
    "Software",
    "Infrastructure"
  ];

  const ExprncFilters = [
    "Learning Lab",
    "Panel",
    "Session",
  ];

  const updateFilters = (filterClicked) => {
    let filterIndex = selectedFilters.indexOf(filterClicked);
    if (filterIndex !== -1) {
      setSelectedFilters(selectedFilters.filter((filter) => {
        return filter !== filterClicked
      }))
    } else {
      setSelectedFilters([...selectedFilters, filterClicked])
    }
  }

  return (

    <FilterContainer>
      {/* <SearchBox>
        <SearchInput value="Search"></SearchInput>
        <SearchButton>?</SearchButton>
      </SearchBox> */}
      <div>Filter Sessions</div>
      <FilterGroup>
        {/* <FilterHeader>
          Event Format
        </FilterHeader>
        {VenueFilters.map(filter => (
          <Filter
            filter={filter}
            key={filter}
            updateFilters={updateFilters}
          />
        ))} */}

        <FilterHeader>
          Topics
        </FilterHeader>
        {filters.map(filter => (
          <Filter
            filter={filter}
            key={filter}
            updateFilters={updateFilters}
          />
        ))}
        <FilterHeader>
          Experience
        </FilterHeader>
        {ExprncFilters.map(filter => (
          <Filter
            filter={filter}
            key={filter}
            updateFilters={updateFilters}
          />
        ))}
      </FilterGroup>
    </FilterContainer>
  );
};

export default SessionFilter;

const Filter = ({ filter, updateFilters }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <StyledFilter
      active={isActive}
      onClick={() => {
        setIsActive(!isActive)
        updateFilters(filter)
      }}
    >
      {filter}
    </StyledFilter>
  )
}

const FilterContainer = styled.div``;
const SearchBox = styled.span`
  height:1rem;
  width:100%;
  height:1rem;
`;
const SearchInput = styled.input`
  width: calc(100% - 3rem);
  background: #f9f9f9;
  padding: .5rem 0 .5rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #eee;
  border-radius: 2rem 0 0 2rem;
`;
const SearchButton = styled.button`
  display: inline-block;
  position: relative;
  right: 0px;
  margin: 0px;
  height: 2.75rem;
  width: 3rem;
  border: 1px solid #eee;
  background: #777;
  content: "?";
  color: #ddd;
  padding-right: 0.66rem;
  border-radius: 0 2rem 2rem 0;
  transition: all 1s;
  &:hover{
    background:#ddd;
    color:#fff;
  }
`;
const FilterButton = styled.button`
  width: 50%;
  color: #7ac24f;
  background: transparent;
  border: 1px solid #7ac24f;
  text-transform: uppercase;
  font-size: 12px;
  &:nth-of-type(2) {
    border-left: 0px;
  }
`;

const FilterGroup = styled.div`
  margin-top: 15px;
`;

const FilterHeader = styled.div`
  background: #f9f9f9;
  padding: .5rem;
  border: 1px solid #eee;
`;

const StyledFilter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: .5rem;
  text-align: left;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color .2s ease-in;
  overflow: hidden;
  font-size: .9rem;
  margin-bottom: 0;
  padding-left: 40px;

  &:after {
    width: 1rem;
    height: 1rem;
    content: '';
    border: ${props => props.active ? "0" : "1px solid #d1d7dc"};
    background-color: ${props => props.active ? "transparent" : "#fff"};
    background-repeat: no-repeat;
    background-image: ${props => props.active ? "url(\"data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.3,7.6L0,9l5.1,5.1L16,3.3l-1.3-1.3l-9.5,9.5L1.3,7.6z' fill='%23615e9b' fill-rule='nonzero'/%3E%3C/svg%3E\");" : ""};
    z-index: 2;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all .2s ease-in;
  }
`;
