import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Collapse } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import { element } from "prop-types";

const ScheduleContainer = styled.div``;
const ScheduleHeader = styled.div`
  margin: 0 auto;
  color: #0085ad;
`;

const ScheduleDate = styled.button`
  width: ${(props) => (props.virtual ? "33.333%" : "25%")};
  padding: 1rem 0px;
  color: #0085ad;
  background: #ffffff;
  border: 1px solid #0085ad;
  border-right: 0px;
  border-radius: 0px;
  &.active {
    position: relative;
    color: #ffffff;
    background: #0085ad;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -10px;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #0085ad;
    }
  }
  &:nth-of-type(2) {
    // border-right:  ${(props) => (props.virtual ? "1px solid #0085ad" : "0")};
    border-right: 1px solid #0085ad;
  }
  &:nth-of-type(3) {
    border-right: 1px solid #0085ad;
  }
  &:nth-of-type(4) {
    border-right: 1px solid #0085ad;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background: #0085ad;
    color: white;
  }
  h4 {
    font-size: 0.9rem;
    margin-bottom: 0px;
  }
  strong {
    font-size: 0.9rem;
  }
  @media (min-width: 768px) {
    h4,
    strong {
      font-size: 1rem;
    }
  }
`;

const ScheduleContent = styled.div`
  margin: 0 auto;
  display: none;
  &.active {
    display: block;
  }
`;

const ScheduleRow = styled.div`
  display: flex;
  padding: 1rem 0;
  .open-icon {
    width: 24px;
    height: 24px;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    color: #0085ad;
  }
`;

const ScheduleIcon = styled.div`
  width: 75px;
  height: 75px;
  background: url(/images/schedule/${(props) => props.icon}.png);
  background-size: cover;
  margin-right: 1rem;
`;

const ScheduleInfo = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  padding: 1rem 0;
  .time {
    font-size: 0.9rem;
    &::after {
      content: " EDT";
    }
  }
  .title {
    font-family: "Avenir-Medium";
  }
`;

const getDayOfWeek = (date) => {
  switch (date) {
    case "November 2":
      return "Sunday, November 2nd";
    case "November 3":
      return "Monday, November 3rd";
    case "November 4":
      return "Tuesday, November 4th";
    case "November 5":
      return "Wednesday, November 5th";
    default:
      return "";
  }
};

const ScheduleItem = ({ icon, date, title, time, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div
      style={{
        borderBottom: "1px solid #0085ad",
      }}
    >
      <ScheduleRow>
        <ScheduleInfo>
          <span className="time">{time}</span>
          <span className="title">{title}</span>
        </ScheduleInfo>
        {/* <FontAwesomeIcon
          className="open-icon"
          icon={faArrowAltCircleDown}
          flip={isOpen ? "vertical" : ""}
        /> */}
      </ScheduleRow>
      {/* <Collapse isOpen={isOpen}>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </Collapse> */}
    </div>
  );
};

const Schedule = ({ type }) => {
  const data = useStaticQuery(graphql`
    query ScheduleQuery {
      allScheduleJson {
        group(field: date) {
          fieldValue
          nodes {
            date
            Description
            Venue
            time
            Title
          }
        }
      }
      allScheduleVirtualJson {
        group(field: date) {
          fieldValue
          nodes {
            date
            Description
            time
            Title
          }
        }
      }
      allScheduleSpanJson {
        group(field: date) {
          fieldValue
          nodes {
            date
            description
            time
            title
          }
        }
      }
    }
  `);

  const [activeScheduleDate, setActiveScheduleDate] = useState(
    type === "virtual" ? "November 2" : "November 2"
  );
  let scheduleData;

  // Feed the component the right data by what type of schedule it is
  if (type === "virtual") {
    console.log("virtual");
    scheduleData = data.allScheduleVirtualJson;
  } else if (type === "span") {
    scheduleData = data.allScheduleSpanJson;
  } else {
    scheduleData = data.allScheduleJson;
  }

  return (
    <ScheduleContainer className="schedule-container">
      <ScheduleHeader>
        {scheduleData.group.map((day) => {
          return (
            <ScheduleDate
              onClick={() => setActiveScheduleDate(day.fieldValue)}
              className={day.fieldValue === activeScheduleDate ? "active" : ""}
              // virtual={type === "virtual" ? true : false}
            >
              <h4>{getDayOfWeek(day.fieldValue)}</h4>
              {/* <strong>{day.fieldValue}th</strong> */}
            </ScheduleDate>
          );
        })}
      </ScheduleHeader>
      {scheduleData.group.map((day) => {
        return (
          <ScheduleContent
            className={day.fieldValue === activeScheduleDate ? "active" : ""}
          >
            {day.nodes.map((item) => {
              return (
                <ScheduleItem
                  icon={item.icon}
                  date={item.date}
                  title={item.Title}
                  time={item.time}
                  description={item.Description}
                />
              );
            })}
          </ScheduleContent>
        );
      })}
    </ScheduleContainer>
  );
};

export default Schedule;
