import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";
import VideoEmbed from "../components/common/video";

const StyledCTA = styled.div`
  padding-top: 3rem;
  text-align: center;
  .login-text {
    margin-top: 1rem;
    font-size: 14px;
    a {
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const CTA = ({ ctaId, page }) => {
  let highlightsButton =
    page === "recap" ? (
      ""
    ) : (
      <Link className="secondary-cta ml-3" to="/reach-recap/">
        2024 Highlights
      </Link>
    );

  switch (ctaId) {
    case "digital":
      return (
        <StyledCTA>
          <h3>Take a look back at Virtual Reach</h3>
          <hr
            style={{
              margin: "1rem auto",
            }}
          />
          <p className="mb-0">
            The content from our 2022 virtual event will remain available until
            March 2023.
          </p>
          <a
            className="primary-cta mt-3"
            href="https://app.swapcard.com/event/xylem"
            target="_blank"
          >
            Log In Now
          </a>
          <p></p>
          {/* {highlightsButton} */}
          {/* <p className="login-text">Need access to Reach 20? <a href="#"_blank">Register here.</a></p> */}
        </StyledCTA>
      );
    case "look-back":
      return (
        <StyledCTA>
          <h3>Take a look back at Reach 2023</h3>
          <hr
            style={{
              margin: "1rem auto",
            }}
          />
          {/* <VideoEmbed
            videoId={"893449896?h=d9eff6c724"}
            ratio={"23.33%"}
          /> */}
          <VideoEmbed videoId={"893449896?h=d9eff6c724"} ratio={"56.25%"} />
        </StyledCTA>
      );
    case "learning-labs":
      return (
        <StyledCTA>
          <h3>Interested in attending a Learning Lab?</h3>
          <p className="mb-0">Register today to claim your spot.</p>
          <a
            className="primary-cta mt-3"
            href="https://cvent.me/#####"
            target="_blank"
          >
            Register Now
          </a>
        </StyledCTA>
      );
    case "sponsor":
      return (
        <StyledCTA>
          <h3>Want to sponsor Reach?</h3>
          <p className="mb-0">Register today to claim your spot.</p>
          <a
            className="primary-cta mt-3"
            href="https://cvent.me/3kmwX9?RefId=reach-sponsor"
            target="_blank"
          >
            Register as a Sponsor
          </a>
        </StyledCTA>
      );
    case "updates":
      return (
        <StyledCTA>
          <h3>Get Reach Updates</h3>
          <p className="mb-0">More to come as we get closer to the event.</p>
          <a
            className="primary-cta mt-3"
            href="/attend/updates/"
            target="_blank"
          >
            Sign Up for Updates
          </a>
        </StyledCTA>
      );
    case "booking-room":
      return (
        <StyledCTA>
          <h3>Reserve your room today.</h3>
          <a
            className="primary-cta mt-3"
            href="https://book.passkey.com/event/50908865/owner/563/home"
            target="_blank"
          >
            Book a Room
          </a>
        </StyledCTA>
      );

    case "vax":
      return (
        <StyledCTA>
          {/*<h3>Upload vaccine card/negative test results.</h3>
                     <p className="mb-0">
                        Upload vaccine card/negative test results.
                    </p> */}
          <a
            className="primary-cta mt-0"
            href="https://inhousephysicians.formstack.com/workflows/reach_conference_vax_record_neg_test_upload?mkt_tok=MzA2LUNKUi0xMDkAAAGG4fpq-hG02NDJ6XoNxjrRZhgGWW0aUmyum651uiv5ciCtOvMmf-CYEhyoSXues1ZdNRETIeeDfAJqWmeFNJGPQ7YYBzRgja5FmKPv0HgxvVYHyA"
            target="_blank"
          >
            Upload Now
          </a>
        </StyledCTA>
      );
    case "watermark":
      return (
        <StyledCTA>
          <h3>Going to volunteer with Watermark?</h3>
          <p className="mb-0">Sign up today to reserve your spot.</p>
          <a
            className="primary-cta mt-3"
            href="https://cvent.me/aKoRZv?RefId=Watermark-volunteer-Reach"
            target="_blank"
          >
            Sign Up Now
          </a>
        </StyledCTA>
      );
    default:
      return (
        <StyledCTA>
          <h3>Ready to go to San Diego, California?</h3>
          <p className="mb-0">Register now to claim your spot.</p>
          <a
            className="primary-cta mt-3"
            href="https://cvent.me/b4EWxL?RefId=reach25-registration"
            target="_blank"
          >
            Register Now
          </a>
        </StyledCTA>
      );
  }
};
export default CTA;
