import Reasons from "../../../../src/components/Reasons/Reasons";
import Link from "gatsby-link";
import reason00 from "../../../../src/images/5reasons/jpg/0.jpg";
import reason01 from "../../../../src/images/5reasons/jpg/1.jpg";
import reason02 from "../../../../src/images/5reasons/jpg/2.jpg";
import reason03 from "../../../../src/images/5reasons/jpg/3.jpg";
import reason04 from "../../../../src/images/5reasons/jpg/4.jpg";
import reason05 from "../../../../src/images/5reasons/jpg/5.jpg";
import reason06 from "../../../../src/images/5reasons/jpg/6.jpg";
import carets from "../../../../src/images/5reasons/svg/5-carets.svg";
import top1 from "../../../../src/images/5reasons/svg/top1.svg";
import top2 from "../../../../src/images/5reasons/svg/top2.svg";
import top3 from "../../../../src/images/5reasons/svg/top3.svg";
import top4 from "../../../../src/images/5reasons/svg/top4.svg";
import top5 from "../../../../src/images/5reasons/svg/top5.svg";
import * as React from 'react';
export default {
  Reasons,
  Link,
  reason00,
  reason01,
  reason02,
  reason03,
  reason04,
  reason05,
  reason06,
  carets,
  top1,
  top2,
  top3,
  top4,
  top5,
  React
};