import React from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faGlobeAmericas,
  faDesktop,
  faUsers,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

const Session = ({ data }) => {
  let title = data.Name ? data.Title : data.Title;
  let venue = data.Category ? data.Category : data.Venue;
  let geo = data.Geo ? data.Geo : data.Geo;
  let startT = data.Start_Time ? data.Start_Time : data.Start_Time;
  let endT = data.End_Time ? data.End_Time : data.End_Time;
  let intT = data.Int_Start_Time ? data.Int_Start_Time : data.Int_Start_Time;

  if (data.Date == "Saturday , Jan 0") {
    var date = "";
  } else {
    var date = data.Date ? data.Date : data.Date;
  }

  if (venue == "In Person") {
    var venueIcon = faUsers;
  }
  if (venue == "Virtual") {
    var venueIcon = faDesktop;
  }
  if (venue == "In Person, Virtual" || venue == "Livestream") {
    var venueIcon = faVideo;
  }
  if (geo == "North America") {
    var geoIcon = faGlobeAmericas;
  }
  if (geo == "International") {
    var geoIcon = faGlobe;
  }
  if (geo == "International, North America") {
    var geoIcon = faGlobe;
  }

  return (
    <SessionContainer>
      <Row>
        {/* <SessionCategory>
          <FontAwesomeIcon icon={venueIcon} /> {venue}
        </SessionCategory> */}
        <SessionTime>
          {date}
          <br /> {startT} - {endT}
        </SessionTime>
        {/* {intT && <IntTime>{intT}</IntTime>}
        {data.Capacity && (
          <SessionCapacity>
            <span>Capacity:</span> {data.Capacity}{" "}
          </SessionCapacity> */}
      </Row>{" "}
      <SessionTitle>{title}</SessionTitle>
      {data.Speaker && (
        <SessionSpeaker
          dangerouslySetInnerHTML={{
            __html: data.Speaker,
          }}
        ></SessionSpeaker>
      )}{" "}
      <SessionDescription
        dangerouslySetInnerHTML={{
          __html: data.Description,
        }}
      />
      {data.Tags && <SessionTags>{data.Tags}</SessionTags>}
      <SessionGeo>
        <FontAwesomeIcon icon={geoIcon}></FontAwesomeIcon> {data.Geo}
      </SessionGeo>
    </SessionContainer>
  );
};

export default Session;

const SessionContainer = styled.div`
  position: relative;
  padding: 1.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid #ecf0f1;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: 100%;
`;

const SessionCategory = styled(Col)`
  max-width: 26ch;
  text-transform: uppercase;
  font-size: 12px;
  color: #333;
  margin-bottom: 1.5rem;
`;

const SessionGeo = styled(Col)`
  font-size: 12px;
  color: #333;
  margin-top: 1rem;
  text-align: right;

  &::before {
    content: "Room: ";
  }
`;

const SessionTitle = styled.h3`
  color: black;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
`;

const SessionSpeaker = styled.p`
  font-size: 14px;
  font-family: "Avenir-Medium";
  color: #615e98;
  cursor: context-menu;

  span {
    content: "";
    display: inline;
    opacity: 0;
    font-family: "Avenir";
    color: #333;
    font-size: 0px;
    transition-property: all;
    transition-duration: 1s;
    transition-delay: 2.5s;

    &::before {
      content: "[";
    }
    &::after {
      content: "]";
    }
  }
  &:hover,
  &:focus {
    span {
      opacity: 1;
      font-size: unset;
      transition-duration: 1.66s;
      transition-delay: 0.25s;
    }
  }
`;

const SessionTags = styled.p`
  width: calc(100% - 36ch);
  font-size: 11px;
  color: #999;
  font-family: "Avenir-Medium";
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 550px) {
    width: unset;
    margin-bottom: 1rem;
  }
`;

const SessionCapacity = styled(Row)`
  max-width: 100%;
  margin: 0 0 15px 0;
  text-align: right;
  font-size: 14px;
  span {
    font-family: "Avenir-Medium";
  }
`;
const SessionTime = styled(Col)`
  text-align: right;
  font-size: 14px;
  margin-bottom: 0.5rem;
  &::after {
    content: " EDT";
  }
`;
const IntTime = styled.p`
  max-width: 100%;
  width: 100%;
  margin: -15px 15px 0 0;
  font-family: "Avenir-Light";
  text-align: right;
  font-size: 12px;
  margin-bottom: 0.5rem;
`;

const SessionDescription = styled.p`
  font-size: 14px;
  margin-bottom: 0px;
`;
