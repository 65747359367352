import React from "react";
import { Link } from "gatsby";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import CTA from "../components/cta-block";

const IconTitle = styled.h4`
    font-family: "Avenir";
    font-weight: 400;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.4;
    padding: 0 0.5rem;
`;

const ImageWrapper = styled.div`
    padding: 2rem 5rem;
    margin: 0 auto;
    max-width: 25rem;
`;

const HealthSafety = () => {
    const distanceImage = getImage();

    return (
        <div>
            <h2>Keeping you safe is our first priority.</h2>
            <hr />
            {/* <p>We’re reaching farther with our safety protocols to ensure a healthy, fun event.</p> */}
            {/* <h4 className="my-auto text-center pb-2" style={{color: "#0085ad"}}>HEALTH & SAFETY UPDATE</h4> 
            <p>The City of New Orleans announced a vaccine requirement for restaurants, bars and large events that includes Reach21. Conference attendees will be required by the city to show proof of vaccination <strong>OR</strong> a negative result on a COVID-19 PCR test. New Orleans requires the negative test every 72 hours. This means attendees will need a negative result on a COVID-19 PCR test just before they arrive and <strong>a second one</strong> during their time in New Orleans. We will be sharing more details on this requirement soon.</p> */}

            <h3 className="text-center mt-5" style={{ color: "#0085ad" }}>What you can expect:</h3>
            <Row className="mb-5">
                <Col lg={4} xs={12}>
                    <ImageWrapper>
                        <StaticImage src="../images/HS_Distancing_Icon.jpg" alt="Distancing" />
                    </ImageWrapper>
                    <IconTitle>Physical distancing throughout the event</IconTitle>
                </Col>
                <Col lg={4} xs={12}>
                    <ImageWrapper>
                        <StaticImage src="../images/HS_VaxCard_Icon.jpg" alt="Proof of Vax" />
                    </ImageWrapper>
                    <IconTitle>Proof of vaccination or negative COVID-19 PCR required</IconTitle>
                    <CTA ctaId="vax" />
                </Col>
                <Col lg={4} xs={12}>
                    <ImageWrapper>
                        <StaticImage src="../images/HS_Mask_Icon.jpg" alt="Masks" />
                    </ImageWrapper>
                    <IconTitle>Masks encouraged and available throughout event</IconTitle>
                    {/* <p style={{fontSize: "1rem"}} className="mb-0 text-center">(unless attendees are actively eating or safely distanced in a session)</p> */}
                </Col>
            </Row>

            <p>We’ve also partnered with Hilton Hotels to ensure a healthy experience from the moment you arrive. <a href="https://hiltoneventreadyplaybook.com/experience/attendee-experience/" target="_blank">Learn more about your safe guest experience</a>.</p>
        </div>
    )
}

export default HealthSafety